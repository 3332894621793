(function ($, Drupal, generic, site) {
  'use strict';

  site.template = site.template || {};

  var siteMyMacV1 = {
    formatter: $('.site-my-mac-v1'),
    signed_in: typeof site.userInfoCookie === 'undefined' ? 0 : parseInt(site.userInfoCookie.getValue('signed_in')),
    is_mobile: typeof site.client.isMobile === 'undefined' ? 0 : parseInt(site.client.isMobile)
  };

  Drupal.behaviors.siteMyMacV1 = {
    get: function (key) {
      return siteMyMacV1[key];
    },

    set: function (key, val) {
      siteMyMacV1[key] = val;
    },

    setMyMac: function (self, thisFormatter) {
      // set first name from the cookie
      if (self.get('signed_in') === 1) {
        var firstName = site.userInfoCookie.getValue('first_name');

        if (firstName && firstName.length > 7) {
          firstName = '';
        }
        thisFormatter.find('.first-name').html(firstName);
      }
    },

    showMyMacContents: function (thisFormatter) {
      var self = this;

      $(document).trigger('click');

      if (self.get('is_mobile') === 0) {
        thisFormatter.addClass('active');

        var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
        var siteContainer = $('.site-header').siblings('.site-container');

        dimOverlay.prependTo(siteContainer);

        thisFormatter.bind('clickoutside', function () {
          thisFormatter.removeClass('active').unbind('clickoutside');
          dimOverlay.remove();
        });

        thisFormatter.find('.site-my-mac__contents-close').once().click(function (e) {
          e.preventDefault();
          thisFormatter.find('.js-my_mac_link').attr('aria-expanded', 'false').focus();
          thisFormatter.trigger('clickoutside');
        });
      }
    },

    setContentsTitle: function (thisFormatter, showAltTitle) {
      var myMacContents = thisFormatter.find('.site-my-mac__contents');

      if (showAltTitle === true) {
        myMacContents.find('.site-my-mac__contents-title').hide();
        myMacContents.find('.site-my-mac__contents-title__alt').show();
      } else {
        myMacContents.find('.site-my-mac__contents-title').show();
        myMacContents.find('.site-my-mac__contents-title__alt').hide();
      }
    },

    setContentsOffset: function (thisFormatter) {
      var siteBag = $('.site-header .site-bag');

      if (siteBag.length === 1) {
        var siteBagWidth = siteBag.outerWidth(false);

        thisFormatter.find('.site-my-mac__contents').css({
          right: '-' + siteBagWidth + 'px'
        });
      }
    },

    setMyMacContents: function (thisFormatter, signed_in) {
      var myMacContents = thisFormatter.find('.site-my-mac__contents');

      if (signed_in === true) {
        myMacContents.find('.site-my-mac__contents-signin').hide();
        myMacContents.find('.site-my-mac__contents-signed_in').show();
      } else {
        myMacContents.find('.site-my-mac__contents-signin').show();
        myMacContents.find('.site-my-mac__contents-signed_in').hide();
      }
    },

    isLoyaltyMember: function () {
      var is_loyalty_member = site.userInfoCookie.getValue('is_loyalty_member');

      return is_loyalty_member === 1;
    },

    isRewardsEligible: function () {
      var is_rewards_eligible = site.userInfoCookie.getValue('is_rewards_eligible');

      return is_rewards_eligible === 1;
    },

    getLoyaltyLevel: function () {
      var loyalty_level = site.userInfoCookie.getValue('loyalty_level');

      return loyalty_level;
    },

    getTierFooterSelector: function (loyaltyLevel) {
      return '.site-my-mac__contents-footer__loyalty-tier' + loyaltyLevel;
    },

    initTCaptcha: function () {
      var self = this;

      if (typeof TencentCaptcha === 'undefined') {
        var body = document.getElementsByTagName('body')[0];
        var script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js';
        script.onreadystatechange = self.scriptLoadedTCaptcha;
        script.onload = self.scriptLoadedTCaptcha;
        body.appendChild(script);
      } else {
        self.scriptLoadedTCaptcha();
      }
    },

    scriptLoadedTCaptcha: function () {
      var $captcha_field = $('#js-gnav-sms-pincode-captcha');
      var $captcha_form = $captcha_field.closest('form');
      var $captcha_submit = $captcha_form.find(':submit');
      var $document = $(document);
      var biz_state = $captcha_form.attr('id') + '_' + parseInt(Math.random() * (9999999999 - 100000000) + 100000000);

      if (typeof $.fn.serializeObject === 'undefined') {
        $.fn.serializeObject = function () {
          var o = {};
          var a = this.serializeArray();

          $.each(a, function () {
            if (o[this.name]) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });

          return o;
        };
      }

      $captcha_submit.on('click', function (evt) {
        evt.preventDefault();
        var $submit_btn = $(this);
        var btn_value = $submit_btn.val();
        var btn_width = $submit_btn.width();
        var btn_height = $submit_btn.height();

        $submit_btn.width(btn_width).height(btn_height).val(' ').addClass('loading').prop('disabled', true);
        // convert to string
        var app_id = '' + $captcha_submit.data('app-id');
        var tcaptcha = new TencentCaptcha(
          app_id,
          function (res) {
            if (res.bizState === biz_state && res.ret === 0) {
              $document.trigger('TCaptcha_GNAV.success');
              $captcha_field.val(btoa(res.ticket + ' ' + res.randstr));
              var formData = $captcha_form.serializeObject();

              generic.jsonrpc.fetch({
                method: 'rpc.form',
                params: [formData],
                onSuccess: function (jsonRpcResponse) {
                  $submit_btn.removeClass('loading').val(btn_value).prop('disabled', false);
                  var resultData = jsonRpcResponse.getData();

                  $document.trigger('SMS_OTP_GNAV.success', [resultData]);
                },
                onFailure: function (ss) {
                  $submit_btn.removeClass('loading').val(btn_value).prop('disabled', false);
                  var errorObjectsArray = ss.getMessages();

                  $document.trigger('SMS_OTP_GNAV.failure', [errorObjectsArray]);
                }
              });
            } else {
              $submit_btn.removeClass('loading').val(btn_value).prop('disabled', false);
            }
          },
          { bizState: biz_state }
        );

        tcaptcha.show();
      });
    },

    initSigninForms: function () {
      var $document = $(document);
      var $signin_form = $('.js-site-my-mac-contents .js-signin');
      var $sms_form = $('.js-site-my-mac-contents .js-signin-new-mobile');
      var $otp_signin_form = $('.js-site-my-mac-contents .js-sms-pincode');
      var $sms_errors = $sms_form.find('.js-signin-errors');
      var $link_signin_form = $('.js-signin-form-link a');
      var $link_sms_otp_form = $('.js-sms-otp-form-link a');
      var $link_send_sms_again = $('.js-send-otp-again a');
      var $sms_form_mobile_field = $sms_form.find('input[name="MOBILE_NUMBER"]');
      var $otp_signin_form_mobile_field = $otp_signin_form.find('input[name="MOBILE_NUMBER"]');
      var $sms_sent_message = $otp_signin_form.find('.js-sms-otp-sent');
      var $privacy_fields = $otp_signin_form.find('.privacy-policy');

      $sms_form_mobile_field.on('change', function () {
        var mobile = $(this).val();

        $otp_signin_form_mobile_field.val(mobile);
      });

      $link_signin_form.on('click', function () {
        $sms_form.not('.hidden').addClass('hidden');
        $otp_signin_form.not('.hidden').addClass('hidden');
        $signin_form.removeClass('hidden');
      });

      $link_sms_otp_form.on('click', function () {
        $signin_form.not('.hidden').addClass('hidden');
        $otp_signin_form.not('.hidden').addClass('hidden');
        $sms_form.removeClass('hidden');
      });

      $link_send_sms_again.on('click', function () {
        $signin_form.not('.hidden').addClass('hidden');
        $otp_signin_form.not('.hidden').addClass('hidden');
        $sms_form.removeClass('hidden');
      });

      $document.on('SMS_OTP_GNAV.success', function (evt, d) {
        $sms_errors.empty();
        $sms_sent_message.removeClass('hidden');
        $sms_form.not('hidden').addClass('hidden');
        $otp_signin_form.removeClass('hidden');
        if (d.is_return_user) {
          $privacy_fields.not('.hidden').addClass('hidden');
        } else {
          $privacy_fields.removeClass('hidden');
        }
      });

      $document.on('SMS_OTP_GNAV.failure', function (evt, errors) {
        $sms_sent_message.not('.hidden').addClass('hidden');
        $sms_errors.empty();
        $.each(errors, function (idx, e) {
          var $li = $('<li class="server"></div>');

          $li.attr('id', e.key).html(e.text);
          $sms_errors.append($li);
        });
      });
    },

    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var formatter = self.get('formatter');
      var $forgotPassword = $('.js-forgot-password', context);
      var $signinEmailAddress = $('.js-return-user-email-gnav', context);
      var $errorListNode = $('.js-signin-errors', context);

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $signinEmailAddress,
          errorListNode: $errorListNode,
          forgotPasswordLink: $forgotPassword
        });
      }

      formatter.each(function () {
        var thisFormatter = $(this);
        var returnUrl = window.location.pathname;
        var signinUrl = '/account/signin.tmpl';
        // Show overlay on click.
        thisFormatter.find('.site-my-mac__link').once().click(function () {
          if (self.get('is_mobile') && self.get('signed_in') !== '1') {
            (returnUrl === '/' || returnUrl === signinUrl) ? window.location = signinUrl : window.location = signinUrl + '?return_url=' + returnUrl;
            return false;
          }
          if (self.get('is_mobile') === 0) {
            if (!thisFormatter.hasClass('active')) {
              $(window).trigger('window::show_login_overlay');
              $(this).children('.js-my_mac_link').attr('aria-expanded', 'true');
              site.restrict_navigation($('.js-my-mac-overlay'));
            } else {
              thisFormatter.removeClass('active');
              thisFormatter.trigger('clickoutside');
            }

            return false;
          }
        }).on('mouseleave', function () {
          $(this).children('.js-my_mac_link').attr('aria-expanded', 'false');
          $('input:focus', thisFormatter).blur();
        });

        // shipping info overlay
        thisFormatter.find('.overlay-toggle').once().click(function () {
          var content = thisFormatter.find('.site-my-mac__overlay-content');

          if (content.length > 0) {
            generic.overlay.launch({
              content: content.clone(true),
              includeBackground: true,
              includeCloseLink: true,
              initialHeight: 0,
              height: 0,
              width: '50%',
              onComplete: function () {
                $(this).colorbox.resize();
              }
            });
          }

          return false;
        });

        // Trigger for sign-in overlay.
        $(window).on('window::show_login_overlay', function () {
          if (!thisFormatter.hasClass('active')) {
            // Timeout ensures click events complete.
            setTimeout(function () {
              self.showMyMacContents(thisFormatter);
            }, 100);
          }
        });

        // swap content if signed in
        if (self.get('signed_in') === 1) {
          self.setContentsTitle(thisFormatter, true);
          self.setMyMacContents(thisFormatter, true);

          var $macSelectPoints = $('.js-mac-select-points');

          if ($macSelectPoints) {
            var points = site.userInfoCookie.getValue('current_available');

            $macSelectPoints.html(points);
          }

          var loyaltyLink = '/account/loyalty/index.tmpl';
          var nonloyaltyLink = '/account/mac-lover.tmpl#offers';
          var redirectLink = parseInt(site.userInfoCookie.getValue('is_loyalty_member')) ? loyaltyLink : nonloyaltyLink;

          $('.js-mac-lover-redirect-link').attr('href', redirectLink);

          if (!!self.isLoyaltyMember() && !!self.isRewardsEligible()) {
            var $footers = thisFormatter.find('.site-my-mac__contents-footer');
            var loyaltyLevel = Number(self.getLoyaltyLevel());

            if (loyaltyLevel >= 0 && loyaltyLevel <= 4) {
              // show personalized messaging about their specific benefits
              var tierFooter = self.getTierFooterSelector(loyaltyLevel);
              var $tierFooter = $(tierFooter);

              $footers.addClass('hidden');
              $tierFooter.removeClass('hidden');
            }
          }
        } else {
          if (sessionStorage.show_my_mac) {
            sessionStorage.removeItem('show_my_mac');
          }

          var facebookSignInForm = thisFormatter.find('.js-facebook_signin');
          var $signInForm = thisFormatter.find('form');
          var formAction = $signInForm.attr('action');

          if (formAction.match(/^http[s]?:/)) {
            // form may already have absolute url (with locale prefixes if this is a hub site), just make sure it's https
            $signInForm.attr('action', formAction.replace('http:', 'https:'));
          } else {
            // default behaviour: assume form POSTs to /account/signin.tmpl and prepend https:// and hostname
            $signInForm.attr('action', 'https://' + window.location.hostname + formAction);
          }

          if (sessionStorage.getItem('loyalty_redirect')) {
            thisFormatter.find('[name="RETURN_URL"]').val(sessionStorage.getItem('loyalty_redirect'));
          } else {
            if (window.location.pathname.search('password_request') > 0 || window.location.pathname.search('confirm') > 0 || window.location.pathname.search('signin') > 0) {
              thisFormatter.find('[name="RETURN_URL"]').val('/account/index.tmpl');
            } else if (location.pathname.match('/checkout/checkout.tmpl')) {
              facebookSignInForm.attr('action', '/checkout/checkout.tmpl');
              facebookSignInForm.find('[name="RETURN_URL"]').val('/checkout/checkout.tmpl');
            } else {
              thisFormatter.find('[name="RETURN_URL"]').val(window.location.pathname + window.location.search + window.location.hash);
            }
          }
        }

        // set initial overlay offset
        self.setContentsOffset(thisFormatter);

        self.initTCaptcha();
        self.initSigninForms();

        // hide overlay on resize, adjust overlay offset
        $(window).resize(_.debounce(function () {
          self.setContentsOffset(thisFormatter);
          if (thisFormatter.hasClass('active')) {
            thisFormatter.trigger('clickoutside');
          }
        }, 50));

        $(window).on('gnavdata_success', function () {
          self.setMyMac(self, thisFormatter);
        });
      });
    },

    attached: false
  };

  $('.favorites-none__sign').click(function (e) {
    /* On PC pops open the overlay, but on mobile needs to redirect, so has the URL in the link  */
    if (Drupal.behaviors.siteMyMacV1.get('is_mobile') === 0) {
      e.preventDefault();
      $(window).trigger('window::show_login_overlay');
    }

    return true;
  });
})(jQuery, Drupal, window.generic || {}, window.site || {});
